export default {
  SERVICES_SUPPLYBASE: {
    TITLE: 'Supply Base Services',
    TITCONTENT1:
      'As an oil and gas industry specialist, we provide integrated supply base solutions in various strategic and remote locations to support our Customer’s onshore and offshore challenging requirements on 24/7 basis.',
    TITCONTENT2:
      'Altus is experienced in executing supply base services in almost every region in Indonesia. We provide overall supply base management services consisting of qualified personnel, QHSE management, security assurances, utilities, and certified lifting equipment for cargo handling to support drilling and project operation.',
    SUBTITLE1: 'Warehouse & Open Yard Management',
    CONTENT1:
      'Altus provides warehouse and open yard facility management including office facilities in supply base areas and assists customers to manage inventory to meet requirements. Our client’s success is based on our focus to always seeking out best practices. We offer high-end solutions across a wide range of integrated logistics and related services.',
    SUBTITLE2: 'Cargo Handling and Equipment Rental',
    CONTENT2:
      'Altus provides a range of certified cargo handling equipment and qualified personnel to manage cargo handling activity in the storage area and also on the jetty. We understand that working in remote areas proves to be a challenge to provide industry-standard cargo handling services, hence our vast experience and local partners help our Customers in achieving efficient and safe operations.'
  }
}

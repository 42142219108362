export default {
  SERVICES_MARINE: {
    TITLE: 'Marine Services',
    TITCONTENT1:
      'Altus provides various services related to vessel or rig activities, which are either provided to the Customer within an integrated package or independently.',
    TITCONTENT2:
      'Altus’ team is experienced in managing transshipment of materials which will, in essence, require movement from one vessel to another, mobilization and demobilization of rigs and vessels, fabrication, vessel agency, chartering, provision of fuel, tank cleaning, technical preparation for material movements, crew changes and marine surveys. All this is performed with high integrity to ensure a smooth process.',
    SUBTITLE1: 'Marine Agency (Vessel & Rig)',
    CONTENT1:
      'Altus works with various Customers to provide reliable marine agency services across the Indonesian archipelago to assist drilling support vessels, rigs, sub-sea, pipelay and other offshore vessel activities. Through Altus Continuous Improvement Process, we achieve best practices by working with a wide range of clients, reflecting on their experiences and the feedback we receive.',
    SUBTITLE2: 'Vessel Charter & Support',
    CONTENT2:
      'As a leading industry specialist, we continue to develop vital partnerships with various vessel owners such as tug and barge, LCT, supply vessels, anchor handling, crew boats, etc in order to serve our customers better and with faster response time. We constantly review and fine-tune our work processes, and retain the best ideas to ensure that we are the cutting edge to deliver the optimal services to our clients.',
    SUBTITLE3: 'Crew Handling & Formalities',
    CONTENT3:
      'Altus provides complete service which includes crew handling, crew change arrangement, crew formalities and related permits. It serves as a crucial auxiliary service to ensure Customers’ timelines are met and crews are well taken care of.',
    SUBTITLE4: 'Owner Protecting Agent',
    CONTENT4:
      'Taking one step further from agency services, Altus provides protective agency for shipowners, charterers, principal to ensure their interest was taken care of. Ensure loading-offloading activity is well managed, just-in-time reporting to principal in case of conflict, calculating best estimate for post disbursement, etc.'
  }
}

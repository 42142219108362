export default {
  TESTIMONIALS: {
    OBJ1: {
      NAME: 'Nurrul Dwi Putranti – Premier Oil Harbour Energy',
      TESTIMONIAL:
        '“So far saya mendapatkan PIC yang sangat communicative dan sangat helpful.”'
    },
    OBJ2: {
      NAME: 'Nova Biringkanae - Fugro',
      TESTIMONIAL:
        '“Thank you for very responsive and providing us excellent support over the course of Bifrost Project. We greatly appreciate it. Keep it up.”'
    },
    OBJ3: {
      NAME: 'Joko Prasetyo – Chevron Rapak Limited',
      TESTIMONIAL:
        '“Mempertahankan pelayanan shipping agency yang sudah baik selama ini.”'
    }
  }
}

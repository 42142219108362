const NAVIGATION_ABOUT_US = 'aboutUsNavs'
const NAVIGATION_PROFILE = 'profileNavs'
const NAVIGATION_NETWORK = 'networkNavs'
const NAVIGATION_SERVICES = 'servicesNavs'
const NAVIGATION_EXPERIENCES = 'experiencesNavs'
const NAVIGATION_CLIENTS = 'clientsNavs'

const navigations = [
  {
    key: NAVIGATION_PROFILE,
    title: 'Profile',
    items: [
      {
        title: 'Overview',
        id: 'overview',
        isActive: true
      },
      {
        title: 'Vision & Mission',
        id: 'vision-mission'
      },
      {
        title: 'Subsidiaries',
        id: 'subsidiaries'
      },
      {
        title: 'Membership',
        id: 'membership'
      }
    ]
  },
  {
    key: NAVIGATION_NETWORK,
    title: 'Network',
    items: [
      {
        title: 'Footprints & Operation in Indonesia',
        id: 'footprints',
        isActive: true
      },
      {
        title: 'Global Partners',
        id: 'global-partners'
      }
    ]
  },
  {
    key: NAVIGATION_CLIENTS,
    title: 'Clients',
    items: [
      {
        title: 'Clients 1',
        id: 'client-1',
        isActive: true
      },
      {
        title: 'Client 2',
        id: 'client-2'
      },
      {
        title: 'Testimonials',
        id: 'testimonial-section'
      }
    ]
  },
  {
    key: NAVIGATION_ABOUT_US,
    title: 'About Us',
    items: [
      {
        title: 'Profile',
        to: { name: 'profile' }
      },
      {
        title: 'Network',
        to: { name: 'network' }
      },
      {
        title: 'Clients',
        to: { name: 'clients' }
      }
    ]
  },
  {
    key: NAVIGATION_SERVICES,
    title: 'Services',
    items: [
      {
        title: 'Logistics Services',
        to: { name: 'logistic-services' }
      },
      {
        title: 'Supply Base Services',
        to: { name: 'supply-base-services' }
      },
      {
        title: 'Marine Services',
        to: { name: 'marine-services' }
      },
      {
        title: 'Manpower Services',
        to: { name: 'manpower-services' }
      },
      {
        title: 'Support Services',
        to: { name: 'support-services' }
      }
    ]
  },
  {
    key: NAVIGATION_EXPERIENCES,
    title: 'Experiences',
    items: [
      {
        title: 'Logistics Services',
        to: { name: 'logistic-experiences' }
      },
      {
        title: 'Supply Base Services',
        to: { name: 'supply-base-experiences' }
      },
      {
        title: 'Marine Services',
        to: { name: 'marine-experiences' }
      },
      {
        title: 'Manpower Services',
        to: { name: 'manpower-experiences' }
      },
      {
        title: 'Support Services',
        to: { name: 'support-experiences' }
      }
    ]
  }
]

export {
  NAVIGATION_ABOUT_US,
  NAVIGATION_PROFILE,
  NAVIGATION_NETWORK,
  NAVIGATION_SERVICES,
  NAVIGATION_EXPERIENCES,
  NAVIGATION_CLIENTS,
  navigations
}

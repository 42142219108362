export default {
  ABOUT_US: {
    PROFILE: {
      OVERVIEW: {
        TITLE: 'Altus History',
        SUBTITLE: 'Experience Since 2003',
        CONTENT: `Founded in 2003, Altus Indonesia started its journey through Logistics and Shipping divisions. Setting its expertise in oil and gas industries, Altus Indonesia ventures into Project Logistics, Power sector, Infrastructure, Heavy Equipment and Energy related sector, offering an integrated end-to-end solutions supported by various overseas presence and global partners.
        <br />
        <br>
        Altus Indonesia is certified and conforms to:
Quality Management System standard ISO 9001:2015,
Environmental Management System standard ISO 14001:2015,
Occupational Health and Safety Management System standard ISO 45001:2018,
Anti-Bribery Management System standard ISO 37001:2016
`
      },
      VISION: {
        TITLE: 'Vision & Mission',
        SUBTITLE1: 'Our-Vision',
        CONTENT1:
          'To be the trusted Logistics Partner providing integrated services for the energy industries',
        SUBTITLE2: 'Our-Mission',
        CONTENT2:
          'To deliver safe and reliable supply chain solutions based on our Customers’ needs',
        SUBTITLE3: 'Our-Values',
        CONTENT3:
          'Agile &nbsp; &#124; &nbsp; Continuity &nbsp; &#124; &nbsp; Excellence &nbsp; &#124; &nbsp; Innovative &nbsp; &#124; &nbsp; Integrity &nbsp; &#124; &nbsp; Teamwork'
      },
      // VALUE: {
      //   TITLE: 'Value',
      //   SUBTITLE: 'sub-heading',
      //   CONTENT: `<p>
      //   QHSE Certification & CSMS Achievement in various Oil & Gas Operator (Rating A for High-risk Operations)
      //     <br />
      //     <br>
      //     Value Added Services – Supply base management, project logistics, marine and oilfield services, manpower etc
      //     <br />
      //     <br>
      //     Successful project management implementation in Indonesia and overseas, including remote set up
      //     <br />
      //     <br>
      //     Organizational Resources & Compliance to tender process requirements - tender admin & analyst (deep understanding of local content)
      //     <br />
      //     <br>
      //     Flexible organization capacity – mix of core competent personnel and project based expertise
      //   </p>`
      // },
      SUBSIDIARIES: {
        TITLE: 'Subsidiaries',
        SUBTITLE: 'sub-heading',
        CONTENT: `PT Altus Logistics Services Indonesia provides innovative, customized, integrated services to various Oil and Gas Clients in Indonesia, such as Logistics, Supply Base Management, Marine Agency Services, Manpower Services, Accommodation Cabin and Offshore Cargo Carrying Unit rental, as well as other Oilfield Support Services in the industry.
        <br />
        <br>
        Lorem, ipsum dolor sit amet consectetur adipisicing elit. Veritatis,
        vel, voluptate similique fugiat maxime nulla quis sint laboriosam
        facilis quisquam doloremque aut. Eligendi mollitia inventore quos,
        voluptatem nesciunt accusamus quisquam!
        <br />
        <br>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Omnis nisi
        doloremque alias dolore odio iure eum ex recusandae voluptatem
        deserunt sit nam quam laboriosam quisquam debitis, ut quis quibusdam
        quaerat.`
      },
      RECOGNITION: {
        TITLE: 'Recognition & Certification',
        SUBTITLE: 'Click on any of the image below to see more',
        CONTENT: `PT Altus Logistics Services Indonesia provides innovative, customized, integrated services to various Oil and Gas Clients in Indonesia, such as Logistics, Supply Base Management, Marine Agency Services, Manpower Services, Accommodation Cabin and Offshore Cargo Carrying Unit rental, as well as other Oilfield Support Services in the industry.
        <br />
        <br>
        Lorem, ipsum dolor sit amet consectetur adipisicing elit. Veritatis,
        vel, voluptate similique fugiat maxime nulla quis sint laboriosam
        facilis quisquam doloremque aut. Eligendi mollitia inventore quos,
        voluptatem nesciunt accusamus quisquam!
        <br />
        <br>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Omnis nisi
        doloremque alias dolore odio iure eum ex recusandae voluptatem
        deserunt sit nam quam laboriosam quisquam debitis, ut quis quibusdam
        quaerat.`
      },
      MEMBERSHIP: {
        TITLE: 'Membership',
        SUBTITLE: 'sub-heading',
        CONTENT: `PT Altus Logistics Services Indonesia provides innovative, customized, integrated services to various Oil and Gas Clients in Indonesia, such as Logistics, Supply Base Management, Marine Agency Services, Manpower Services, Accommodation Cabin and Offshore Cargo Carrying Unit rental, as well as other Oilfield Support Services in the industry.
        <br />
        <br>
        Lorem, ipsum dolor sit amet consectetur adipisicing elit. Veritatis,
        vel, voluptate similique fugiat maxime nulla quis sint laboriosam
        facilis quisquam doloremque aut. Eligendi mollitia inventore quos,
        voluptatem nesciunt accusamus quisquam!
        <br />
        <br>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Omnis nisi
        doloremque alias dolore odio iure eum ex recusandae voluptatem
        deserunt sit nam quam laboriosam quisquam debitis, ut quis quibusdam
        quaerat.`
      }
    }
  }
}

export default {
  SERVICES_LOGISTIC: {
    TITLE: 'Logistics Services',
    CONTENT:
      'As a trusted logistics partner, we deliver safe, reliable secure and cost-effective freight, intermodal and multimodal transport solutions, tailored for our Customer’s needs.',
    SUBTITLE1: 'International & Domestic Freight Forwarding',
    CONTENT1:
      'Altus provides freight management and multi-modal cargo transportation option - sea, air or land transportation that will give flexibility and efficiency for cargo shipment.',
    SUBTITLE2: 'Project Cargo',
    CONTENT2:
      'Altus offers reliable solutions to move heavy and complex cargo including but not limited to route surveys, operation plans, stowage and last-mile delivery. With our extensive experience, we are able to secure necessary arrangement that meets our Customer’s demand and high standards of safety.',
    SUBTITLE3: 'Customs Clearance',
    CONTENT3:
      'Altus provides logistics formalities services, including but not limited to general Customs Clearance, Masterlist and SKJLN arrangement. We ensure no delays in delivering the customs process and documentation.',
    SUBTITLE4: 'Hand-carry & Hotshot',
    CONTENT4:
      'Altus provides arrangements for spot charter transportation as required on an urgent basis.'
  }
}

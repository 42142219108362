import { navigations } from '@/core/constant/navigation'

export const CHANGE_LANGUAGE = 'ChangeAppLanguage'
export const CHANGE_LOADING_STATE = 'ChangeLoadingState'
export const CHANGE_NAVIGATION_ITEMS = 'ChangeNavigationItems'

const state = {
  projectName: 'Altus',
  appLanguage: 'en',
  availableLanguage: [
    { code: 'id', flag: 'indonesia.png', name: 'Indonesian' },
    { code: 'en', flag: 'united-kingdom.png', name: 'English' }
  ],
  isLoading: false,
  currentNavigation: {
    title: '',
    items: []
  }
}

const getters = {
  projectName: state => state.projectName,
  appLanguage: state => state.appLanguage,
  availableLanguage: state => state.availableLanguage,
  isLoading: state => state.isLoading,
  currentNavigation: state => state.currentNavigation
}

const mutations = {
  [CHANGE_LANGUAGE](state, payload) {
    state.appLanguage = payload
  },
  [CHANGE_LOADING_STATE](state, payload) {
    state.isLoading = payload
  },
  [CHANGE_NAVIGATION_ITEMS](state, payload) {
    state.currentNavigation = payload
  }
}

const actions = {
  [CHANGE_LANGUAGE]({ commit }, payload) {
    commit(CHANGE_LANGUAGE, payload)
  },
  [CHANGE_LOADING_STATE]({ commit }, payload) {
    commit(CHANGE_LOADING_STATE, payload)
  },
  [CHANGE_NAVIGATION_ITEMS]({ commit }, payload) {
    const targetNavs = navigations.filter(x => x.key === payload)
    if (targetNavs.length > 0) {
      commit(CHANGE_NAVIGATION_ITEMS, targetNavs[0])
    } else {
      commit(CHANGE_NAVIGATION_ITEMS, null)
    }
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}

<template>
  <metainfo>
    <template v-slot:title="{ content }">{{
      content ? `${content} | Altus Indonesia` : `Altus Indonesia`
    }}</template>
  </metainfo>
  <router-view />
</template>

<script>
</script>

<style>
</style>

export default {
  HOMEPAGE: {
    TITLE: 'Welcome to <br> Altus Indonesia',
    SUBTITLE1:
      'Altus Indonesia is your trusted Logistics Partner providing integrated services for the energy industries.',
    SUBTITLE2:
      'Altus Indonesia delivers safe and reliable supply chain solutions based on Customers’ needs.',
    SUBTITLE3:
      'Altus Indonesia embraces these Values: Agile, Continuity, Excellence, Innovative, Integrity, Teamwork.',
    MORE_INFORMATION: 'More Information'
  }
}

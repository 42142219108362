export default {
  SERVICES_SUPPORT: {
    TITLE: 'Support Services',
    CONTENT:
      'Recognizing our Customer’s needs, we develop our Support Services as a key value-add to support their dynamic operations. Our Support Service capabilities range from the provision of   inspections, seamless delivery of Cargo Carrying Unit (CCU) and portacamp, comprehensive airport ground handling and customized procurement services for our Customer in the energy industries.',
    SUBTITLE1: 'Heavy Equipment & Vehicle Inspection',
    CONTENT1:
      'Altus provides inspection services performed by our certified and trained personnel for both vehicles and heavy equipment, mainly to support Geothermal project activities. We are also registered in EBTKE (Directorate General of New Energy, Renewable and Energy Conservation) to ensure we are up to date on industry standards. This shows our commitment to safe operations and contributes to no loss time incidents in Customer operations.',
    SUBTITLE2: 'Offshore Cargo Carrying Unit Rental',
    CONTENT2:
      'Altus supplies a wide range of DNV 2.7-1 certified cargo carrying units, baskets, containers and tanks for the oil and gas market and other sectors that have offshore projects.',
    SUBTITLE3: 'Portacamp Rental',
    CONTENT3:
      'Altus provides accommodation services for our Customers covering various facility functions or modifications into office containers, sleeping containers, kitchen and pantry, sports room, toilets, etc.',
    SUBTITLE4: 'Airport Ground Handling',
    CONTENT4:
      'Altus provides ground handling services for Customer’s aircraft both Fixed Wing and Rotary Wing, including but not limited to passenger’s luggage and cargo movement, manifest, and active participation in aviation security and safety.',
    SUBTITLE5: 'Procurement Services',
    CONTENT5:
      'Altus caters to your procurement needs adhering to the best quality products, prices and delivery. It is our commitment to provide a seamless service supported by our excellent supplier’s network and relations.',
    SUBTITLE6: 'Rig Lay Ups',
    CONTENT6:
      'Altus team will work with you to design the best schemes that meet your marine asset’s specific requirements.'
  }
}

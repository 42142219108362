export const routes = [
  {
    path: '/',
    name: 'customerModule',
    component: () => import('@/layouts/CustomerParent.vue'),
    children: [
      {
        name: 'homepage',
        path: '',
        component: () => import('@/views/.customer/pages/HomePageV2')
      },
      {
        name: 'contactus',
        path: '/contactus',
        component: () => import('@/views/.customer/pages/ContactUs')
      }
    ]
  },

  {
    path: '/',
    name: 'contentModule',
    component: () => import('@/layouts/ContentParent.vue'),
    children: [
      {
        name: 'profile',
        path: '/about-us/profile',
        component: () => import('@/views/.customer/pages/about-us/Profile')
      },
      {
        name: 'network',
        path: '/about-us/network',
        component: () => import('@/views/.customer/pages/about-us/Network')
      },
      {
        name: 'clients',
        path: '/about-us/clients',
        component: () => import('@/views/.customer/pages/about-us/Clients')
      },
      {
        name: 'QHSE',
        path: '/about-us/QHSE',
        component: () => import('@/views/.customer/pages/about-us/QHSE')
      },
      {
        name: 'logistic-experiences',
        path: '/logistic-experiences',
        component: () => import('@/views/.customer/pages/ProjectPage')
      },
      {
        name: 'supply-base-experiences',
        path: '/supply-base-experiences',
        component: () => import('@/views/.customer/pages/ProjectPage')
      },
      {
        name: 'marine-experiences',
        path: '/marine-experiences',
        component: () => import('@/views/.customer/pages/ProjectPage')
      },
      {
        name: 'manpower-experiences',
        path: '/manpower-experiences',
        component: () => import('@/views/.customer/pages/ProjectPage')
      },
      {
        name: 'support-experiences',
        path: '/support-experiences',
        component: () => import('@/views/.customer/pages/ProjectPage')
      },
      {
        name: 'logistic-services',
        path: '/services/logistic-services',
        component: () =>
          import('@/views/.customer/pages/services/LogisticServices')
      },
      {
        name: 'supply-base-services',
        path: '/services/supply-base-services',
        component: () =>
          import('@/views/.customer/pages/services/SupplyBaseServices')
      },
      {
        name: 'marine-services',
        path: '/services/marine-services',
        component: () =>
          import('@/views/.customer/pages/services/MarineServices')
      },
      {
        name: 'manpower-services',
        path: '/services/manpower-services',
        component: () =>
          import('@/views/.customer/pages/services/ManpowerServices')
      },
      {
        name: 'support-services',
        path: '/services/support-services',
        component: () =>
          import('@/views/.customer/pages/services/SupportServices')
      },
      {
        name: 'news',
        path: '/news',
        component: () => import('@/views/.customer/pages/News')
      }
    ]
  },
  {
    path: '/admin',
    component: () => import('@/layouts/side-menu/Main.vue'),
    children: [
      {
        path: '',
        name: 'adminDashboard',
        component: () => import('@/views/.admin/Projects/Index.vue')
      },
      {
        path: 'admins',
        name: 'baseAdmins',
        component: () => import('@/layouts/BaseParent.vue'),
        children: [
          {
            path: '',
            name: 'adminAdmins',
            component: () => import('@/views/.admin/Admins/Index.vue')
          }
        ]
      },
      {
        path: 'projects',
        name: 'baseProjects',
        component: () => import('@/layouts/BaseParent.vue'),
        children: [
          {
            path: '',
            name: 'adminProjects',
            component: () => import('@/views/.admin/Projects/Index.vue')
          }
        ]
      }
    ]
  },
  {
    name: 'adminLogin',
    path: '/admin/login',
    component: () => import('../views/login/Main.vue')
  }
]

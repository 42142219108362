export default {
  SERVICES_MANPOWER: {
    TITLE: 'Manpower Services',
    CONTENT:
      'As part of Altus’s main service, we offer innovative talent management solutions in the areas of recruitment, outsourcing (starting from entry-level to expert and executive positions in functional areas), business process outsourcing, contingent workforce outsourcing and payroll services.',
    SUBTITLE1: 'Outsourcing & Consulting Management',
    CONTENT1:
      'Altus provides both National or Expatriates specialists, skilled manpower and consultants to support Customer’s operations in oil and gas, minerals & mining, petrochemicals and the power sector.',
    SUBTITLE2: 'Rig Crew Services',
    CONTENT2:
      'Altus provides Customers with a wide diversity of crew and specialists for drilling projects, including local crew which will help Customer to engage with the local community while having the chance to increase local content.  supported includes other supplies and services.',
    SUBTITLE3: 'HR Management Services',
    CONTENT3:
      'Altus provides solutions in managing the services of recruitment, payroll, training & development with up to 600 personnel at any one time. It is our main objective to ensure the payroll process is conducted smoothly while abiding to applicable manpower law and tax regulation in Indonesia.'
  }
}
